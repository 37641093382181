import React from "react";

export const TitleHome = ({ text }) => {
  return (
    <div className="flex items-center justify-center">
      <hr className="border my-4 flex-grow" />
      <h2 className="text-2xl font-bold tracking-tight text-gray-900 mx-4">
        {text}
      </h2>
      <hr className="border my-4 flex-grow" />
    </div>
  );
};
