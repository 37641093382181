import React, { useEffect, useState } from "react";
import Type from "../components/Types";
import { useParams, useNavigate } from "react-router-dom";
import { TypeAPI } from "../api/TypeAPI";
const TypePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [types, setTypes] = useState([
    { id: 1, name: "All products", category_id: id },
  ]);
  useEffect(() => {
    setLoading(true);
    setTypes([{ id: 1, name: "All products", category_id: id }]);
    fetchTypes();
  }, [id]);

  const fetchTypes = async () => {
    try {
      await TypeAPI.getByCategory(id).then((typesApi) => {
        setTypes((types) => [...types, ...typesApi.data]);
        setLoading(false);
      });
    } catch (error) {
      navigate("/");
    }
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div>
          <Type type={types} />
        </div>
      )}
    </div>
  );
};

export default TypePage;
