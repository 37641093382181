export const productMock = {
  name: "RIVA VELOUR TAUPE Unisex",
  price: "30.50",
  discount: "50",
  description:
    'TEST The Basic Tee 6-Pack allows you to fully express your vibrant personality with three grayscale options. Feeling adventurous? Put on a heather gray tee. Want to be a trendsetter? Try our exclusive colorway: "Black". Need to add an extra pop of color to your outfit? Our white tee has you covered.',
  variants: [
    {
      color: "125478",
      sizes: [
        {
          id: "76c740d3-fb2d-4064-a77b-8ffa35cb32e5",
          size: "42",
          quantity: 0,
          inStock: true,
        },
        {
          id: "cf4ddcde-c8bc-43fe-a481-c786883e36b7",
          size: "45",
          quantity: 13,
          inStock: true,
        },
      ],
      images: [
        {
          name: "https://genuins.com/cdn/shop/products/Mesadetrabajo12_1.jpg?v=1707752661",
          alt: "Secondary product shot",
          tag: 1,
        },
        {
          name: "https://genuins.com/cdn/shop/files/G105297_a509d1ef-9d13-4f0e-b674-a72ed962d62e.jpg?v=1707752661",
          alt: "Tertiary product shot 1",
          tag: 2,
        },
        {
          name: "https://genuins.com/cdn/shop/products/Mesadetrabajo2_47_97203f61-f7d7-4553-8d20-f734d4bd72ae.jpg?v=1707752661",
          alt: "Secondary product shot",
          tag: 1,
        },
        {
          name: "https://genuins.com/cdn/shop/products/Mesadetrabajo12_1.jpg?v=1707752661",
          alt: "Tertiary product shot 1",
          tag: 2,
        },
      ],
    },
    {
      color: "BBBBBB",
      sizes: [
        {
          id: "f07e34d6-1c7c-4726-87a3-f845fe523bb",
          size: "42",
          quantity: 10,
          inStock: true,
        },
      ],
      sizes: [
        {
          id: "f07e34d6-1c7c-4726-87a3-f845f3bdb",
          size: "37",
          quantity: 10,
          inStock: true,
        },
        {
          id: "f07e34d6-1c7c-4726-87a3-45fe523bdb",
          size: "38",
          quantity: 10,
          inStock: true,
        },
        {
          id: "f07e34d6-1c7c-46-87a3-f845fe523bdb",
          size: "39",
          quantity: 10,
          inStock: false,
        },
        {
          id: "f07e34d6-1c7c726-87a3-f845fe523bdb",
          size: "40",
          quantity: 10,
          inStock: true,
        },
        {
          id: "f07e34d6c7c-4726-87a3-f845fe523bdb",
          size: "41",
          quantity: 10,
          inStock: true,
        },
        {
          id: "f07e34-1c7c-4726-87a3-f845fe52bdb",
          size: "42",
          quantity: 10,
          inStock: true,
        },
        {
          id: "07e34d6-1c7c-4726-87a3-f845fe5bdb",
          size: "43",
          quantity: 10,
          inStock: false,
        },
        {
          id: "fe34d6-1c7c-4726-87a3-f845fe523db",
          size: "44",
          quantity: 10,
          inStock: true,
        },
        {
          id: "f07e34d6-1c787c-4726-87a3-f845fe523bdb",
          size: "45",
          quantity: 10,
          inStock: false,
        },
      ],
      images: [
        {
          name: "https://genuins.com/cdn/shop/products/riva2.jpg?v=1700738486",
          alt: "Secondary product shot",
          tag: 1,
        },
        {
          name: "https://genuins.com/cdn/shop/files/Disenosintitulo_100.jpg?v=1703245068",
          alt: "Tertiary product shot 1",
          tag: 2,
        },
        {
          name: "https://genuins.com/cdn/shop/products/riva2.jpg?v=1700738486",
          alt: "Secondary product shot",
          tag: 1,
        },
        {
          name: "https://genuins.com/cdn/shop/files/Disenosintitulo_100.jpg?v=1703245068",
          alt: "Tertiary product shot 1",
          tag: 2,
        },
      ],
    },
  ],
};

export const productsMock = [
  {
    id: 1,
    name: "Basic Tee",
    price: "35",
    color: "Black",
    discount: null,
    outOfStock: false,
    images: [
      {
        name: "https://genuins.com/cdn/shop/files/G104348_2.png?v=1699616562",
        tag: 1,
      },
    ],
  },
  {
    id: 2,
    name: "Basic Tee",
    price: "35",
    color: "Black",
    discount: "70",
    outOfStock: false,
    images: [
      {
        name: "https://genuins.com/cdn/shop/files/G104348_2.png?v=1699616562",
        tag: 1,
      },
    ],
  },
  {
    id: 3,
    name: "Basic Tee",
    price: "35",
    color: "Black",
    discount: "70",
    outOfStock: false,
    images: [
      {
        name: "https://genuins.com/cdn/shop/files/G104348_2.png?v=1699616562",
        tag: 1,
      },
    ],
  },
  {
    id: 4,
    name: "Basic Tee",
    price: "35",
    color: "Black",
    discount: "70",
    outOfStock: false,
    images: [
      {
        name: "https://genuins.com/cdn/shop/files/G104348_2.png?v=1699616562",
        tag: 1,
      },
    ],
  },
  {
    id: 5,
    name: "Basic Tee",
    price: "35",
    color: "Black",
    discount: "70",
    outOfStock: false,
    images: [
      {
        name: "https://genuins.com/cdn/shop/files/G104348_2.png?v=1699616562",
        tag: 1,
      },
    ],
  },
  {
    id: 6,
    name: "Basic Tee",
    price: "35",
    color: "Black",
    discount: "70",
    outOfStock: false,
    images: [
      {
        name: "https://genuins.com/cdn/shop/files/G104348_2.png?v=1699616562",
        tag: 1,
      },
    ],
  },
  {
    id: 7,
    name: "Basic Tee",
    price: "35",
    color: "Black",
    discount: "70",
    outOfStock: false,
    images: [
      {
        name: "https://genuins.com/cdn/shop/files/G104348_2.png?v=1699616562",
        tag: 1,
      },
    ],
  },
  {
    id: 8,
    name: "Basic Tee",
    price: "35",
    color: "Black",
    discount: "70",
    outOfStock: false,
    images: [
      {
        name: "https://genuins.com/cdn/shop/files/G104348_2.png?v=1699616562",
        tag: 1,
      },
    ],
  },
  {
    id: 9,
    name: "Basic Tee",
    price: "35",
    color: "Black",
    discount: "70",
    outOfStock: false,
    images: [
      {
        name: "https://genuins.com/cdn/shop/files/G104348_2.png?v=1699616562",
        tag: 1,
      },
    ],
  },
  {
    id: 10,
    name: "Basic Tee",
    price: "35",
    color: "Black",
    discount: "70",
    outOfStock: false,
    images: [
      {
        name: "https://genuins.com/cdn/shop/files/G104348_2.png?v=1699616562",
        tag: 1,
      },
    ],
  },
  {
    id: 11,
    name: "Basic Tee",
    price: "35",
    color: "Black",
    discount: "70",
    outOfStock: false,
    images: [
      {
        name: "https://genuins.com/cdn/shop/files/G104348_2.png?v=1699616562",
        tag: 1,
      },
    ],
  },
  {
    id: 12,
    name: "Basic Tee",
    price: "35",
    color: "Black",
    discount: "70",
    outOfStock: false,
    images: [
      {
        name: "https://genuins.com/cdn/shop/files/G104348_2.png?v=1699616562",
        tag: 1,
      },
    ],
  },
  {
    id: 13,
    name: "Basic Tee",
    price: "35",
    color: "Black",
    discount: "70",
    outOfStock: false,
    images: [
      {
        name: "https://genuins.com/cdn/shop/files/G104348_2.png?v=1699616562",
        tag: 1,
      },
    ],
  },
];

export const ProductMockBestSeller = [
  {
    id: "3c5b4f5f-99c3-4c37-b52e-2e968d7b8e43",
    name: "MALLORCA H2O CELESTE",
    price: "38.00",
    discount: null,
    outOfStock: false,
    images: [
      {
        name: "17142488488904-adc56095-ea90-42f1-b134-94ed7b7aa959.jpg",
        tag: 1,
      },
      {
        name: "17142488486994-adc56095-ea90-42f1-b134-94ed7b7aa959.jpg",
        tag: 2,
      },
    ],
  },
  {
    id: "29221d2b-2fc4-4a8e-a08a-83a869dcb285",
    name: "LOIS VELOUR TAUPE",
    price: "76.00",
    discount: null,
    outOfStock: false,
    images: [
      {
        name: "17142484845896-72b456a2-4dae-4f2a-a167-f7d0657ace1e.jpg",
        tag: 1,
      },
      {
        name: "17142484847060-72b456a2-4dae-4f2a-a167-f7d0657ace1e.jpg",
        tag: 2,
      },
      {
        name: "17142484848505-72b456a2-4dae-4f2a-a167-f7d0657ace1e.jpg",
        tag: 3,
      },
    ],
  },
  {
    id: "391b2e86-a549-4611-9390-b96931d7c9a3",
    name: "CRISTI COCO VEGAN BLACK",
    price: "86.00",
    discount: null,
    outOfStock: false,
    images: [
      {
        name: "17142482604860-cea9dfed-19f6-4fcc-aa33-7edd5628cb13.jpg",
        tag: 1,
      },
      {
        name: "17142482604045-cea9dfed-19f6-4fcc-aa33-7edd5628cb13.jpg",
        tag: 2,
      },
      {
        name: "17142482600598-cea9dfed-19f6-4fcc-aa33-7edd5628cb13.jpg",
        tag: 3,
      },
    ],
  },
  {
    id: "03774846-7567-4c19-815d-0848d5eba69f",
    name: "CORT VELOUR BLACK",
    price: "97.00",
    discount: null,
    outOfStock: false,
    images: [
      {
        name: "17142469108785-0671fca9-e8ec-4f89-8631-5e99756f9067.jpg",
        tag: 1,
      },
      {
        name: "17142469108961-0671fca9-e8ec-4f89-8631-5e99756f9067.jpg",
        tag: 2,
      },
    ],
  },
  {
    id: "ff7ddd29-d378-436b-a362-59890fc54f49",
    name: "MALLORCA H2O KAKI",
    price: "38.00",
    discount: null,
    outOfStock: false,
    images: [
      {
        name: "17139075875396-60e868ab-6a0f-4c40-bc01-58a0a6a6aa60.JPG",
        tag: 1,
      },
      {
        name: "17139075878112-60e868ab-6a0f-4c40-bc01-58a0a6a6aa60.JPG",
        tag: 2,
      },
    ],
  },
  {
    id: "e6bf2529-9025-4048-a915-666d39982456",
    name: "MALLORCA H2O BLACK",
    price: "38.00",
    discount: null,
    outOfStock: false,
    images: [
      {
        name: "17139074039716-d3f07373-6214-45a4-b087-30d9c060297d.JPG",
        tag: 1,
      },
      {
        name: "17139074033059-d3f07373-6214-45a4-b087-30d9c060297d.JPG",
        tag: 2,
      },
    ],
  },
  {
    id: "e44c7819-f1d3-47f7-8eb5-f38f1661bd41",
    name: "SHETLAND OILED LEATHER KAKI",
    price: "59.00",
    discount: "30",
    outOfStock: false,
    images: [
      {
        name: "17142463666139-6831022e-bb88-4645-802a-2e233653e9c3.jpg",
        tag: 1,
      },
      {
        name: "17142463663543-6831022e-bb88-4645-802a-2e233653e9c3.jpg",
        tag: 2,
      },
    ],
  },
  {
    id: "c46ea716-dda3-49bf-9b75-5607ea0d81b6",
    name: "KINDU MOON IRIDESCENT",
    price: "54.00",
    discount: "30",
    outOfStock: false,
    images: [
      {
        name: "17142461596320-0c16bb54-0899-42cf-a714-c42032536564.jpg",
        tag: 1,
      },
      {
        name: "17142461596945-0c16bb54-0899-42cf-a714-c42032536564.jpg",
        tag: 2,
      },
      {
        name: "17142461599229-0c16bb54-0899-42cf-a714-c42032536564.jpg",
        tag: 3,
      },
    ],
  },
  {
    id: "0d5500bf-803a-4a80-b5ff-5c8feefc62c7",
    name: "KINDU PARCHIS NAVY",
    price: "49.00",
    discount: "30",
    outOfStock: false,
    images: [
      {
        name: "17142459638045-f36235b3-b92f-497a-a985-aaae3c7509fb.jpg",
        tag: 1,
      },
      {
        name: "17142459635835-f36235b3-b92f-497a-a985-aaae3c7509fb.jpg",
        tag: 2,
      },
      {
        name: "17142459636350-f36235b3-b92f-497a-a985-aaae3c7509fb.jpg",
        tag: 3,
      },
    ],
  },
  {
    id: "f153ae56-a1a1-4fa1-9040-707ba2a03c78",
    name: "KINDU MULTI SKY",
    price: "49.00",
    discount: "30",
    outOfStock: false,
    images: [
      {
        name: "17142454983542-3c914283-5e2b-476f-844f-f43f3388b9c0.jpg",
        tag: 1,
      },
      {
        name: "17142454984404-3c914283-5e2b-476f-844f-f43f3388b9c0.jpg",
        tag: 2,
      },
      {
        name: "17142454984415-3c914283-5e2b-476f-844f-f43f3388b9c0.jpg",
        tag: 3,
      },
    ],
  },
];
