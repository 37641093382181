import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ShoppingCartState from "../src/components/shoppingCart/ShoppingCartState";
import ReactPixel from "react-facebook-pixel";
const root = ReactDOM.createRoot(document.getElementById("root"));

const options = {
  autoConfig: true,
  debug: false,
};

ReactPixel.init("503973962035764", options);

ReactPixel.pageView();

root.render(
  // <React.StrictMode>
  <ShoppingCartState>
    <App />
  </ShoppingCartState>
  // </React.StrictMode>
);

reportWebVitals();
