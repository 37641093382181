import React from "react";
import { API_URL_IMAGE_ITEM } from "../api/URL/constants";
import { useContext } from "react";
import ShoppingCartProductContext from "../components/shoppingCart/ShoppingCartProductContext";
import { sumItems } from "../components/shoppingCart/ShoppingCartReducer";

const ProductCardCheckout = () => {
  const { cartItems, checkout, clearCart } = useContext(
    ShoppingCartProductContext
  );
  const { itemCount, total } = sumItems(cartItems);
  return (
    <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6">
      {itemCount >= 1 ? (
        <div>
          {cartItems.map((product) => (
            <div
              className="flex flex-col rounded-lg bg-white sm:flex-row"
              key={product.id}
            >
              <img
                className="m-2 h-24 w-28 rounded-md border object-cover object-center"
                src={API_URL_IMAGE_ITEM + product.image}
                alt=""
              />
              <div className="flex w-full flex-col px-4 py-4">
                <span className="font-semibold">{product.name}</span>
                <div className="flex justify-between">
                  <div className="flex space-x-2 mt-2 mb-2">
                    <div
                      className="w-5 h-5 rounded-full flex items-center justify-center"
                      style={{
                        backgroundColor: "#" + product.color,
                        opacity: "75%",
                        borderWidth: 1,
                        borderColor: "black",
                      }}
                    ></div>
                    {/* <span className="float-right text-gray-400">{product.size}</span> */}
                    <div className="w-5 h-5 border border-black flex items-center justify-center">
                      <span className="text-black text-xs">{product.size}</span>
                    </div>
                  </div>
                  <div>
                    <p>X{product.quantity}</p>
                  </div>
                </div>
                {product.discount !== null ? (
                  <div className="flex">
                    <p className="line-through text-gray-500">
                      ${product.price}
                    </p>
                    <p className="">
                      $
                      {(
                        ((100 - product.discount) / 100) *
                        product.price
                      ).toFixed(2)}
                      $
                    </p>
                  </div>
                ) : (
                  <p className="text-lg font-bold">{product.price}$</p>
                )}
              </div>
            </div>
          ))}
          <div className="mt-6 border-t border-b py-2">
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Subtotal</p>
              <p className="font-semibold text-gray-900">
                ${parseFloat(total).toFixed(2)}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Shipping</p>
              <p className="font-semibold text-gray-900">$0.00</p>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-between">
            <p className="text-sm font-medium text-gray-900">Total</p>
            <p className="text-2xl font-semibold text-gray-900">
              ${(parseFloat(total) + 0).toFixed(2)}
            </p>
          </div>
        </div>
      ) : (
        <p className="text-center opacity-50">Your cart is empty</p>
      )}
    </div>
  );
};

export default ProductCardCheckout;
