import React from "react";
import { Link } from "react-router-dom";
export default function Category({ category }) {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:py-15 lg:max-w-none lg:py-14">
          <div className=" space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
            {category.map((c, index) => (
              <Link to={"/items/filter?category=" + c.id} key={index}>
                <div
                  key={c.name + index + c.id}
                  className="group relative overflow-hidden mb-4"
                >
                  <div className="relative h-auto w-full opacity-85 transition-transform transform scale-100 hover:scale-110 hover:opacity-100 overflow-hidden  bg-black">
                    <img
                      src={c.imageSrc}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <h3 className="m-auto text-xl absolute inset-x-0 top-0 text-center justify-center text-white font-bold mt-60">
                    <span className="absolute inset-0" />
                    {c.name}
                  </h3>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
