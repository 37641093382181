import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const ItemFiltredAPI = {
  getAll: async function (page, params = {}, cancel = false) {
    let url = `api/items/filter?per_page=12&page=${page}`;
    if (
      params.categoryId !== undefined &&
      params.categoryId !== null &&
      params.categoryId !== "1"
    ) {
      url += `&category_id=${params.categoryId}`;
    }
    if (
      params.typeId !== undefined &&
      params.typeId !== null &&
      params.typeId !== "1"
    ) {
      url += `&type_id=${params.typeId}`;
    }
    if (
      params.collectionId !== undefined &&
      params.collectionId !== null &&
      params.collectionId !== "1"
    ) {
      url += `&collection_id=${params.collectionId}`;
    }
    if (params.search !== undefined && params.search !== null) {
      url += `&search=${params.search}`;
    }

    const response = await api.request({
      url: url,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  getMultipleTypesItems: async function (page, ids, cancel = false) {
    let url = `api/items/multiple/types?per_page=12&page=${page}&ids=${ids}`;
    const response = await api.request({
      url: url,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(ItemFiltredAPI);
