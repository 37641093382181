import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import Home from "../page/Home";
import ProductPage from "../page/ProductPage";
import ProductDetails from "../components/ProductDetails";
import TypePage from "../page/TypePage";
import AboutUs from "../page/AboutUs";
import ContactUs from "../page/ContactPage";
import Checkout from "../page/Checkout";
import Faq from "../page/FAQ";
import OrderVerification from "../page/OrderVerification";
import NoPage from "../page/NoPage";
import PointOfSales from "../page/POS";
import MultipleTypesItems from "../components/MultipleTypesItems";
const Platform = lazy(() => import("../layout/layout"));

const RoutesGenAPP = () => {
  return (
    <Router forceRefresh={false}>
      <ScrollToTopOnMount />
      <Suspense fallback={<LinearProgress />}>
        <Routes>
          <Route path="/" element={<Platform />}>
            <Route index element={<Home />} />
            <Route path="/items/filter" element={<ProductPage />} />
            <Route path="/item/:id" element={<ProductDetails />} />
            <Route path="/pdp" element={<ProductDetails />} />
            <Route path="/types/:id" element={<TypePage />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/Checkout" element={<Checkout />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/point-of-sale" element={<PointOfSales />} />
            <Route
              path="/order/verification/:id"
              element={<OrderVerification />}
            />
            <Route
              path="/items/multiple/types"
              element={<MultipleTypesItems />}
            />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default RoutesGenAPP;
