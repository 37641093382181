import React, { useState, useEffect } from "react";
import ProductList from "../components/ProductList";
import { productsMock } from "../data/MockData";
import { ItemFiltredAPI } from "../api/ItemFiltredAPI";
import { useLocation } from "react-router-dom";
const MultipleTypesItems = () => {
  const [loading, setLoading] = useState(true);
  const [productsFetchedData, setProductsFetchedData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [reachedBottom, setReachedBottom] = useState(false);
  const [ids, setIds] = useState(null);
  const [pagination, setPagination] = useState({
    current_page: null,
    last_page: null,
    per_page: null,
    total: null,
  });
  const [page, setPage] = useState({ page: 0 });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //   useEffect(() => {
  //     scrollToTop();
  //   });

  useEffect(() => {
    fetchProducts();
  }, [ids]);

  const fetchProducts = () => {
    try {
      ItemFiltredAPI.getMultipleTypesItems(parseInt(page.page) + 1, ids).then(
        (items) => {
          if (productsFetchedData.length < items.total) {
            const getProducts = items.data;
            setProductsFetchedData((prevProducts) => {
              const existingIds = new Set(
                prevProducts.map((product) => product.id)
              );
              const newProducts = getProducts.filter(
                (product) => !existingIds.has(product.id)
              );
              return [...prevProducts, ...newProducts];
            });

            setPagination({
              current_page: items.current_page,
              total: items.total,
              last_page: items.last_page,
              per_page: items.per_page,
            });
            setLoading(false);
            if (items.current_page < items.last_page) {
              setPage({ page: parseInt(page.page) + 1 });
              setReachedBottom(false);
            } else {
              setLoadingProducts(false);
            }
          } else {
            setLoadingProducts(false);
            setLoading(false);
          }
        }
      );
    } catch (error) {}
  };
  useEffect(() => {
    if (searchParams.has("ids")) {
      setIds(searchParams.get("ids"));
    }
  }, [location.search]);
  useEffect(() => {
    const handleScroll = () => {
      const offsetHeight = document.documentElement.offsetHeight;
      const btmHeight = (offsetHeight * 20) / 100;
      const offsetHeightWithOutBtm = offsetHeight - btmHeight;
      const innerHeight = window.innerHeight;
      const scrollTop = document.documentElement.scrollTop;

      const hasReachedBottom =
        offsetHeightWithOutBtm - (innerHeight + scrollTop) <= 10;

      setReachedBottom(hasReachedBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (reachedBottom) {
      if (productsFetchedData.length >= 12) {
        setLoadingProducts(true);
        setTimeout(() => {
          fetchProducts();
        }, 1000);
      }
    }
  }, [reachedBottom]);

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div>
          <ProductList
            products={
              process.env.REACT_APP_DEV === "true"
                ? productsMock
                : productsFetchedData
            }
            text={"ALL Products"}
          />
          {loadingProducts && (
            <div className="flex mt-10 justify-center items-center ">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default MultipleTypesItems;
