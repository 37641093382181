import React from "react";

const TextWithTopBar = ({ phrase }) => {
  return (
    <>
      <hr className="border my-8" />
      <h2 className="text-xl mt-10 font-serif tracking-tight text-gray-900 text-center">
        {phrase}
      </h2>
    </>
  );
};

export default TextWithTopBar;
