import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TitleHome } from "./TitleHome";
import {
  API_URL_IMAGE_ITEM,
  API_URL_IMAGE_RESIZED,
} from "../api/URL/constants";
import { Link } from "react-router-dom";
import { ProductAPI } from "../api/ProductAPI";
const CarouselRow = ({ MockedProducts }) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState(null);

  useEffect(() => {
    fetchApi();
  }, []);

  function deleteElementViaId(tableau, name) {
    return tableau.filter((element) => {
      return element.name !== name;
    });
  }

  const fetchApi = async () => {
    try {
      await ProductAPI.getBestSellers().then((fetchedProducts) => {
        //we delete this product bcz the product hasn't a correct image size
        setProducts(deleteElementViaId(fetchedProducts, "TIKA VEGAN SALMON"));
        setLoading(false);
      });
    } catch (error) {
      //TODO the MockedProducts should be compatible with the mapping
      // setProducts(MockedProducts);
    }
  };
  const isMobile = window.innerWidth <= 768;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          fade: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          fade: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: true,
        },
      },
    ],
  };

  return (
    <div className="mx-auto px-4 mb-16 sm:px-6 lg:px-8 max-w-7xl ">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div>
          <div className="mb-5">
            <TitleHome text={"Best Seller"} />
          </div>
          <Slider {...settings}>
            {products.map((product, index) =>
              isMobile ? (
                <Link to={"/item/" + product.id} key={index}>
                  <div className="pr-4">
                    <div className="p-4 border mb-6 border-gray-200 rounded-md shadow-md">
                      <img
                        src={
                          API_URL_IMAGE_RESIZED +
                          product.variants[0].images[0].name +
                          "?width=480&quality=90"
                        }
                        alt={product.name}
                        className="w-full h-auto object-cover rounded-md mb-2"
                      />
                      <div className="mt-4 flex justify-between">
                        <div>
                          <h3 className="text-sm text-gray-700">
                            <span
                              aria-hidden="true"
                              className="absolute inset-0"
                            />
                            {product.name}
                          </h3>
                        </div>
                        {product.discount !== null ? (
                          <div>
                            <p className="text-sm font-medium text-gray-900 line-through">
                              {product.price}$
                            </p>
                            <p className="text-sm font-medium text-gray-900">
                              $
                              {(
                                ((100 - product.discount) / 100) *
                                product.price
                              ).toFixed(2)}
                              $
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p className="text-sm font-medium text-gray-900">
                              {product.price}$
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              ) : (
                <div className="pr-4" key={index}>
                  <div className="p-4 border mb-6 border-gray-200 rounded-md shadow-md">
                    <img
                      src={
                        API_URL_IMAGE_RESIZED +
                        product.variants[0].images[0].name +
                        "?width=480&quality=90"
                      }
                      alt={product.name}
                      className="w-full h-auto object-cover rounded-md mb-2"
                    />
                    <div className="mt-4 flex justify-between">
                      <div>
                        <h3 className="text-sm text-gray-700">
                          <span
                            aria-hidden="true"
                            className="absolute inset-0"
                          />
                          {product.name}
                        </h3>
                      </div>
                      {product.discount !== null ? (
                        <div>
                          <p className="text-sm font-medium text-gray-900 line-through">
                            {product.price}$
                          </p>
                          <p className="text-sm font-medium text-gray-900">
                            $
                            {(
                              ((100 - product.discount) / 100) *
                              product.price
                            ).toFixed(2)}
                            $
                          </p>
                        </div>
                      ) : (
                        <div>
                          <p className="text-sm font-medium text-gray-900">
                            {product.price}$
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            )}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default CarouselRow;
