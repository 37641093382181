import React, { useEffect, useState } from "react";
import ProductCardCheckout from "../components/ProductCardCheckout";
import { useContext } from "react";
import ShoppingCartProductContext from "../components/shoppingCart/ShoppingCartProductContext";
import { sumItems } from "../components/shoppingCart/ShoppingCartReducer";
import CustomerDetailsForm from "../components/CustomerDetailsForm";
import { OrderAPI } from "../api/OrderAPI";
import DialogOrderDone from "../components/DialogOrderDone";
import ReactPixel from "react-facebook-pixel";
import DialogOrderCancelled from "../components/DialogOrderCancelled";
const CheckoutPage = () => {
  const { cartItems, clearCart } = useContext(ShoppingCartProductContext);
  const { itemCount, total } = sumItems(cartItems);
  const [loading, setLoading] = useState(false);
  const [showDialogOrderDone, setShowOrderDialogDone] = useState(false);
  const [showDialogOrderCancelled, setShowOrderDialogCancelled] =
    useState(false);
  const [orderId, setOrderId] = useState("");
  // Sample shipping methods data
  const shippingMethods = [
    {
      id: 1,
      name: "Aramex Delivery",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/e/ee/Aramex_logo.svg",
      description: "Delivery: 2-4 Days",
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const submitForm = async (formData) => {
    const items = cartItems.map((item) => {
      return { id: item.id, quantity: item.quantity };
    });
    formData.items = items;
    if (process.env.REACT_APP_DEV === "true") {
      setLoading(false);
      setShowOrderDialogDone(true);
      clearCart();
    } else {
      try {
        await OrderAPI.submit(formData).then((response) => {
          ReactPixel.track("Purchase", {
            value: (parseFloat(total) + 0).toFixed(2),
            currency: "$",
          });
          setLoading(false);
          setOrderId(response.orderId);
          setShowOrderDialogDone(true);
          clearCart();
        });
      } catch (error) {
        setLoading(false);
        clearCart();
        setShowOrderDialogCancelled(true);
      }
    }
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div>
          {showDialogOrderDone ? <DialogOrderDone orderId={orderId} /> : null}
          {showDialogOrderCancelled ? <DialogOrderCancelled /> : null}
          <div className="flex flex-col items-center border-b bg-white py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-32">
            <div className="mt-4 py-2 text-xs sm:mt-0 sm:ml-auto sm:text-base">
              <div className="relative">
                <ul className="relative flex w-full items-center justify-between space-x-2 sm:space-x-4">
                  <li className="flex items-center space-x-3 text-left sm:space-x-4">
                    <a
                      className="flex h-6 w-6 items-center justify-center rounded-full bg-emerald-200 text-xs font-semibold text-emerald-700"
                      href="#"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </a>
                    <span className="font-semibold text-gray-900">Shop</span>
                  </li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                  <li className="flex items-center space-x-3 text-left sm:space-x-4">
                    <a
                      className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-600 text-xs font-semibold text-white ring ring-gray-600 ring-offset-2"
                      href="#"
                    >
                      2
                    </a>
                    <span className="font-semibold text-gray-900">
                      Shipping
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
            <div className="px-4 pt-8">
              <p className="text-xl font-medium">Order Summary</p>
              <p className="text-gray-400">Check your items!</p>

              <ProductCardCheckout />
              <div className="mt-8 text-lg font-medium">Shipping Methods</div>
              <form className="mt-5 grid gap-6">
                {shippingMethods.map((method) => (
                  <div key={method.id} className="relative">
                    <input
                      className="peer hidden"
                      id={`radio_${method.id}`}
                      type="radio"
                      name="radio"
                      defaultChecked
                    />
                    <span className="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
                    <label
                      className="peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4"
                      htmlFor={`radio_${method.id}`}
                    >
                      <img
                        className="w-14 object-contain"
                        src={method.image}
                        alt=""
                      />
                      <div className="ml-5">
                        <span className="mt-2 font-semibold">
                          {method.name}
                        </span>
                        <p className="text-slate-500 text-sm leading-6">
                          {method.description}
                        </p>
                      </div>
                    </label>
                  </div>
                ))}
              </form>
            </div>
            <CustomerDetailsForm
              FormCustomerDetails={submitForm}
              loading={() => setLoading(true)}
              scrollTo={scrollToTop}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckoutPage;
