import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const TypeAPI = {
  getByCategory: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/categories/${id}/types`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getByCategory.name].handleRequestCancellation()
            .signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(TypeAPI);
