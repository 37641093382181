import carousel1 from "../assets/images/BANNER_WEB-2.webp";
import carousel2 from "../assets/images/MADE_IN_SPAIN-ESPANA.webp";
import home1 from "../assets/imagesNotCompressed/home1.png";
import home2 from "../assets/imagesNotCompressed/new.png";
export const CarouselData = [
  {
    image: home2,
  },
  // {
  //   image:
  //     "https://genuins.com/cdn/shop/files/BANNER_WEB_PRINCIPAL.jpg?v=1711376271&width=1600",
  // },
];
