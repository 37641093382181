import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { OrderAPI } from "../api/OrderAPI";
const OrderVerification = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [isOrderConf, setIsOrderConf] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Error");
  useEffect(() => {
    apicall();
  }, []);

  const apicall = async () => {
    try {
      await OrderAPI.get(id).then(() => {
        setIsOrderConf(true);
        setLoading(false);
      });
    } catch (error) {
      if (error.response.data.error !== null) {
        setErrorMessage(error.response.data.error);
      }
      setLoading(false);
    }
  };
  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div>
          <div className="bg-white">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-2xl sm:py-15 lg:max-w-none py-14">
                <div className="space-y-12  ">
                  <div className="flex justify-center items-center h-full">
                    {isOrderConf ? (
                      <div className="bg-green-100 border-l-4  border-green-500 text-green-700 p-4 rounded-lg lg:w-1/2">
                        <p className="text-lg font-semibold">
                          Order Status: Confirmed
                        </p>
                        <p>
                          Your order has been successfully confirmed and is now
                          being processed.
                        </p>
                      </div>
                    ) : (
                      <div className="bg-red-100 border-l-4  border-red-500 text-red-700 p-4 rounded-lg lg:w-1/2">
                        <p className="text-lg font-semibold">
                          Order Status: {errorMessage}
                        </p>
                        <p>
                          Apologies for the inconvenience, but there was an
                          error, and your order hasn't been confirmed. If you've
                          received an order confirmation email along with an
                          invoice, it means your order has already been
                          confirmed.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default OrderVerification;
