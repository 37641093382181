import React, { Suspense, useEffect } from "react";
import RoutesGenAPP from "./router";
import { LinearProgress } from "@mui/material";
function App() {
  useEffect(() => {
    document.title = "GENUINS Lebanon";
  });
  return (
    <Suspense fallback={<LinearProgress />}>
      <RoutesGenAPP />
    </Suspense>
  );
}

export default App;
