import { useState, useEffect, useContext } from "react";
import { RadioGroup } from "@headlessui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { productMock } from "../data/MockData";
import { ProductAPI } from "../api/ProductAPI";
import { useParams, useNavigate } from "react-router-dom";
import { API_URL_IMAGE_ITEM } from "../api/URL/constants";
import ShoppingCartProductContext from "./shoppingCart/ShoppingCartProductContext";
import SizeGuide from "./sizeGuide";
import Accordion from "./Accordion";
import Notification from "./Notification";
const selected = { selectedClass: "ring-gray-400" };

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductDetails() {
  const { addToCart, cartItems, increase } = useContext(
    ShoppingCartProductContext
  );

  const isInCart = (product) => {
    return !!cartItems.find((item) => item.id === product.id);
  };

  const getQuantity = (product) => {
    const foundItem = cartItems.find((item) => item.id === product.id);
    return foundItem ? foundItem.quantity : 0;
  };

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [product, setProduct] = useState(null);
  const [productShoppingCart, setProductShoppingCart] = useState(null);

  useEffect(() => {
    const isDev = process.env.REACT_APP_DEV === "true";
    if (isDev) {
      setSelectedColor(productMock.variants[0]);
      setSelectedSize(productMock.variants[0].sizes[0]);
      setProduct(productMock);
      setProductShoppingCart({
        name: productMock.name,
        price: productMock.price,
        discount: productMock.discount,
        id: productMock.variants[0].sizes[0].id,
        color: productMock.variants[0].color,
        image: productMock.variants[0].images[0].name,
        size: productMock.variants[0].sizes[0].size,
        quantity_in_stock: productMock.variants[0].sizes[0].quantity,
      });
      setLoading(false);
    } else {
      apiCall();
    }
  }, []);

  const sortSizes = (sizes) => {
    return sizes.sort((a, b) => a.size.localeCompare(b.size));
  };

  const apiCall = async () => {
    if (id) {
      try {
        await ProductAPI.get(id).then((productFetchingData) => {
          setProduct(productFetchingData);
          setSelectedColor(productFetchingData.variants[0]);
          setSelectedSize(productFetchingData.variants[0].sizes[0]);
          setProductShoppingCart({
            name: productFetchingData.name,
            price: productFetchingData.price,
            discount: productFetchingData.discount,
            id: productFetchingData.variants[0].sizes[0].id,
            color: productFetchingData.variants[0].color,
            image: productFetchingData.variants[0].images[0].name,
            size: productFetchingData.variants[0].sizes[0].size,
          });
          setLoading(false);
        });
      } catch {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (selectedSize !== null) {
      setProductShoppingCart({
        ...productShoppingCart,
        id: selectedSize.id,
        size: selectedSize.size,
        color: selectedColor.color,
        quantity_in_stock: selectedSize.quantity,
      });
    }
  }, [selectedSize]);

  const [showNotification, setShowNotification] = useState(false);

  const handleClose = () => {
    setShowNotification(false);
  };
  const handleAddToCart = () => {
    // check if the product is on the shopping cart
    if (isInCart(productShoppingCart)) {
      if (
        productShoppingCart.quantity_in_stock > getQuantity(productShoppingCart)
      ) {
        setShowNotification(true);
        increase(productShoppingCart);
      }
    } else {
      setShowNotification(true);
      addToCart(productShoppingCart); // Add the product to the cart
    }
  };
  return (
    <div className="bg-white">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div className="pt-6">
          <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
            {/* Image gallery */}
            <div className="mx-auto max-w-2xl sm:px-6">
              <Carousel showStatus={false} showThumbs={false}>
                {selectedColor.images.map((image, index) => (
                  <div
                    key={index}
                    className="aspect-h-4 aspect-w-3 overflow-hidden"
                  >
                    {process.env.REACT_APP_DEV === "true" ? (
                      <img
                        src={image.name}
                        alt={image.name}
                        className="h-full w-full object-cover object-center"
                      />
                    ) : (
                      <img
                        src={API_URL_IMAGE_ITEM + image.name}
                        alt={API_URL_IMAGE_ITEM + image.name}
                        className="h-full w-full object-cover object-center"
                      />
                    )}
                  </div>
                ))}
              </Carousel>
            </div>
            {/* Product info */}
            {showNotification && (
              <Notification
                title="Notification Title"
                message="Notification Message"
                onClose={handleClose} // Step 4: Pass handleClose function as prop
              />
            )}
            {/* Product name */}
            <div className="lg:border-l lg:border-gray-200 lg:pl-8">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                {product.name}
              </h1>
              {/* Display discounted price if discount is available */}
              {product.discount ? (
                <div className="flex items-center">
                  <p className="text-3xl tracking-tight text-gray-400 line-through">
                    {product.price}$
                  </p>
                  <p className="text-3xl tracking-tight text-black ml-2">
                    {(((100 - product.discount) / 100) * product.price).toFixed(
                      2
                    )}
                    $
                    <span className="ml-2 text-sm font-bold text-white rounded bg-black px-3">
                      -{product.discount}%
                    </span>
                  </p>
                </div>
              ) : (
                <p className="text-3xl tracking-tight text-gray-900">
                  {product.price}$
                </p>
              )}
              {/* <p className="text-3xl tracking-tight text-gray-900">
                {product.price}$
              </p> */}
              {/* Color selection */}
              <div>
                <h3 className="text-sm font-medium text-gray-900">Color</h3>
                <RadioGroup
                  value={selectedColor}
                  onChange={setSelectedColor}
                  className="mt-4"
                >
                  <RadioGroup.Label className="sr-only">
                    Choose a color
                  </RadioGroup.Label>
                  <div className="flex items-center space-x-3">
                    {product.variants.map((variant) => (
                      <RadioGroup.Option
                        key={variant.color}
                        value={variant}
                        style={{
                          backgroundColor: "#" + variant.color,
                          opacity: "75%",
                        }}
                        className={({ active, checked }) =>
                          classNames(
                            variant.color,
                            active && checked ? "ring ring-offset-1" : "",
                            !active && checked ? "ring-2" : "",
                            "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
                          )
                        }
                      >
                        <RadioGroup.Label as="span" className="sr-only">
                          {variant.color}
                        </RadioGroup.Label>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            variant.color,
                            "h-8 w-8 rounded-full border border-black border-opacity-10"
                          )}
                        />
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
              {/* Size selection */}
              <div className="mt-10">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900">Size</h3>
                  <SizeGuide title="Size guide" />
                </div>
                <RadioGroup
                  value={selectedSize}
                  onChange={setSelectedSize}
                  className="mt-4"
                >
                  <RadioGroup.Label className="sr-only">
                    Choose a size
                  </RadioGroup.Label>
                  <div className="grid grid-cols-8 gap-4 sm:grid-cols-8 lg:grid-cols-8">
                    {sortSizes(selectedColor.sizes).map((size) => (
                      <RadioGroup.Option
                        key={size.id}
                        value={size}
                        disabled={!(size.quantity > 0)}
                        className={({ active }) =>
                          classNames(
                            size.quantity > 0
                              ? "cursor-pointer bg-white text-gray-900 shadow-sm"
                              : "cursor-not-allowed bg-gray-50 text-gray-200",
                            active ? "ring-2 ring-black" : "",
                            "group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6"
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <RadioGroup.Label as="span">
                              {size.size}
                            </RadioGroup.Label>
                            {size.quantity > 0 ? (
                              <span
                                className={classNames(
                                  active ? "border" : "border-2",
                                  checked
                                    ? "border-black"
                                    : "border-transparent",
                                  "pointer-events-none absolute -inset-px rounded-md"
                                )}
                                aria-hidden="true"
                              />
                            ) : (
                              <span
                                aria-hidden="true"
                                className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                              >
                                <svg
                                  className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                  viewBox="0 0 100 100"
                                  preserveAspectRatio="none"
                                  stroke="currentColor"
                                >
                                  <line
                                    x1={0}
                                    y1={100}
                                    x2={100}
                                    y2={0}
                                    vectorEffect="non-scaling-stroke"
                                  />
                                </svg>
                              </span>
                            )}
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
              {/* Add to bag button */}
              <button
                type="submit"
                onClick={handleAddToCart} // Call handleAddToCart function on click
                className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-black px-8 py-3 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
              >
                ADD TO CART
              </button>
              {/* Notification Component */}
              {showNotification && (
                <Notification
                  title="Added to Cart"
                  message="The product has been added to your cart."
                  onClose={handleClose}
                />
              )}
              <div>
                {product.description !== null ? (
                  <Accordion
                    className="mt-20 flex-1 pt-7"
                    title="DESCRIPTION"
                    content={product.description}
                  />
                ) : null}
              </div>
              <Accordion
                className="mt-20 flex-1 pt-7"
                title="SIZE GUIDE"
                content={
                  <>
                    <SizeGuide title="Consult our size guide" />
                  </>
                }
              />{" "}
              <Accordion
                className="mt-20 flex-1 pt-7"
                title="MANUFACTURING"
                content="ALWAYS FROM SPAIN: All our models are designed and manufactured in our facilities located in Elche under careful and sustainable production. Our suppliers, friends, are local and nearby companies that we fully trust."
              />{" "}
              <Accordion
                className="mt-20 flex-1 pt-7"
                title="SUSTAINABILE MATERIALS
              "
                content={
                  "As part of our environmental action plan, we have been exclusively working with sustainable materials for years. 100% Spanish materials and suppliers for a dream product made entirely in Spain."
                }
              />{" "}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
