import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProductLoadingLogo from "../assets/logo/loading_product_image.png";
import AllproductsWomen from "../assets/images/women all prodcuts.webp";
import AllproductsMan from "../assets/images/allproducts_man.webp";
import AllproductsKids from "../assets/images/allproducts_kids.webp";
import ClassicsMan from "../assets/images/classics-man.webp";
import ClassicsWomen from "../assets/images/classics-women.webp";
import ClogsKids from "../assets/images/clogs_kids.webp";
import ClogsWomen from "../assets/images/women clogs.webp";
import AquaMan from "../assets/images/aqua_man.webp";
import AquaWomen from "../assets/images/aqua_women.webp";
import SandalWomen from "../assets/images/sandals_women.webp";
import genuinsHomeMan from "../assets/images/men genuins home.webp";
import clogsMan from "../assets/images/men clogs.webp";
import genuinsHomeWomen from "../assets/images/women genuins home.webp";

export default function Types({ type }) {
  // souwar hala2 hene woman
  // souwar man
  // Aqua https://genuins.com/cdn/shop/products/Mesadetrabajo7_9_large.jpg?v=1710494285
  // clasiics https://genuins.com/cdn/shop/products/3_large.jpg?v=1707752501
  // All product https://cdn.shopify.com/s/files/1/1090/8230/files/productoweb15002000_84.jpg?v=1710345329
  // souwar Kids
  // clogs https://genuins.com/cdn/shop/files/Mesadetrabajo3_large.png?v=1713430172
  // sandals https://genuins.com/cdn/shop/files/productoweb15002000-2024-04-10T124808.630.jpg?v=1712748131&width=1200
  // All product https://genuins.com/cdn/shop/files/Mesadetrabajo5_large.png?v=1713430127
  const typesImages = [
    {
      name: "All products",
      srcwoman: AllproductsWomen,
      srcman: AllproductsMan,
      srckids: AllproductsKids,
    },
    {
      name: "CLASSICS",
      srcwoman: ClassicsWomen,
      srcman: ClassicsMan,
    },
    {
      name: "CLOGS",
      srcwoman: ClogsWomen,
      srckids: ClogsKids,
      srcman: clogsMan,
    },
    {
      name: "AQUA",
      srcwoman: AquaWomen,
      srcman: AquaMan,
    },
    {
      name: "SANDALS",
      srcwoman: SandalWomen,
      srckids: SandalWomen,
    },
    {
      name: "Genuins Home",
      srcwoman: genuinsHomeWomen,
      srcman: genuinsHomeMan,
    },
    {
      name: "default",
      srcwoman: ClassicsWomen,
      srckids: ClassicsWomen,
      srcman: ClassicsWomen,
    },
  ];

  const getSourceByName = (name) => {
    const itemName = name.toLowerCase();
    const item = typesImages.find(
      (item) => item.name.toLowerCase() === itemName
    );
    return item ? item : getSourceByName("default");
  };

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:py-15 lg:max-w-none lg:py-14">
          <div className=" space-y-12 lg:grid lg:grid-cols-4 lg:gap-x-6 lg:space-y-0">
            {type.map((t, index) =>
              t.id === 1 ? (
                <Link
                  to={"/items/filter?category=" + t.category_id}
                  key={index}
                >
                  <div key={t.name} className="group relative overflow-hidden">
                    <div
                      style={{ backgroundImage: `url(${ProductLoadingLogo})` }}
                      // className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-no-repeat bg-center bg-cover bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80 relative"

                      className="relative mb-7 h-96 w-full opacity-85 transition-transform transform scale-100 hover:scale-110 hover:opacity-100 overflow-hidden  bg-black"
                    >
                      {t.category_id ===
                      "c6a2851f-c427-4915-93c6-75c674b98664" ? (
                        <LazyLoadImage
                          src={getSourceByName(t.name).srcman}
                          className="h-full w-full object-cover object-center"
                          width={275}
                          height={380}
                        />
                      ) : // <img
                      //   src={getSourceByName(t.name).srcman}
                      //   className="h-full w-full object-cover object-center"
                      // />
                      // if category is kids
                      t.category_id ===
                        "d2d883a8-b765-480c-a61a-29ff661c6690" ? (
                        <img
                          src={getSourceByName(t.name).srckids}
                          className="h-full w-full object-cover object-center"
                        />
                      ) : t.category_id ===
                        "e1019b39-1d60-432c-817c-6b52a4ab0b17" ? (
                        <img
                          src={getSourceByName(t.name).srcwoman}
                          className="h-full w-full object-cover object-center"
                        />
                      ) : (
                        <img
                          src={getSourceByName(t.name).srcwoman}
                          // alt={t.imageAlt}
                          className="h-full w-full object-cover object-center"
                        />
                      )}

                      {/* <img
                        src="https://genuins.com/cdn/shop/files/Disenosintitulo_6_large.png?v=1713518722"
                        // alt={t.imageAlt}
                        className="h-full w-full object-cover object-center"
                      /> */}
                    </div>
                    <h3 className="m-auto text-sm absolute inset-x-0 top-0 text-center justify-center text-white font-bold mt-40">
                      <span className="absolute inset-0" />
                      {t.name}
                    </h3>
                  </div>
                </Link>
              ) : (
                <Link to={"/items/filter?type=" + t.id} key={index}>
                  <div key={t.name} className="group relative overflow-hidden">
                    <div className="relative mb-7 h-96 w-full opacity-85 transition-transform transform scale-100 hover:scale-110 hover:opacity-100 overflow-hidden  bg-black">
                      {/* // if category is Men */}
                      {t.category_id ===
                      "c6a2851f-c427-4915-93c6-75c674b98664" ? (
                        <img
                          src={getSourceByName(t.name).srcman}
                          className="h-full w-full object-cover object-center"
                        />
                      ) : // if category is kids
                      t.category_id ===
                        "d2d883a8-b765-480c-a61a-29ff661c6690" ? (
                        <img
                          src={getSourceByName(t.name).srckids}
                          className="h-full w-full object-cover object-center"
                        />
                      ) : t.category_id ===
                        "e1019b39-1d60-432c-817c-6b52a4ab0b17" ? (
                        <img
                          src={getSourceByName(t.name).srcwoman}
                          className="h-full w-full object-cover object-center"
                        />
                      ) : (
                        <img
                          src={getSourceByName(t.name).srcwoman}
                          // alt={t.imageAlt}
                          className="h-full w-full object-cover object-center"
                        />
                      )}
                    </div>
                    <h3 className="m-auto text-sm absolute inset-x-0 top-0 text-center justify-center text-white font-bold mt-40">
                      <span className="absolute inset-0" />
                      {t.name}
                    </h3>
                  </div>
                </Link>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
