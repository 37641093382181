import React from "react";
import { Typography } from "@material-tailwind/react";

const faqs = [
  {
    title: "About the size",
    desc: "If it is the first time you use a bio plant, there are several things that you should know: the last is anatomical, this means that you will see some pronunciations in the plant, necessary for the correct positioning of the foot. At the beginning it may find you strange, but as soon as you try them, you will not be able to take them away! We advise on our genuins, choose the size you usually use. Keep in mind that some millimeters (approximate half centimeter) must be left over both in the front and rear of the Bio, so that the foot rests correctly.",
    desc1:
      "genuins It offers you a free size change. Write us to contact@genuinslb.com and we will tell you how to make the change",
  },
  {
    title: "How to clean the sandals genuins?",
    desc: "Cork plant: clean with a wet wipe and let dry in vertical position before saving it",
    desc1: "Serraje sandals: Clean with a soft brush with fine bristles.",
  },
  {
    title: "How to clean genuins Home?",
    desc:
      "Outside part:<br />" +
      " Most Home models are made with felt, in this case, they can be cleaned with a sponge with soap and water to remove spots, avoiding rubbing very strong.",
    desc1:
      "Interior part:<br/> " +
      "Plant with Pelito: Clean with a wipe or wet cloth, then dry with the hair dryer to prevent the little one from being caked and remained soft.<br /><br />" +
      "Cork plant:<br/> " +
      "clean with a wet wipe and let dry in vertical position before saving it.<br />" +
      "Steraje plant: Clean with a soft brush with fine bristles. All our models have cork anatomical plant, so the use of washing machine is advised.",
  },
  {
    title: "About orders? ",
    desc: "In special periods such as sales, promotions, items preordist shipping deadlines can be increased.",
    desc1:
      "Do you have any problem with your order? <br/>" +
      "Contact us through contact@genuinslb.com and we will resolve it as soon as possible.",
  },
  {
    title: "About payments? ",
    desc: "What payment methods are available?",
    desc1: "You can pay only by cash on delivery",
  },
  {
    title: "About changes and returns",
    desc: "No refunds, but you can change by size or any other item online or in store by the presence of the box, voucher and label within 15 days from receiving your order.",
    desc1:
      "What happens if there is an error in the order or a manufacturing failure?<br/>" +
      "In the event that the product received presents a manufacturing failure or the order is wrong, we replace it without any charge. genuins It will assume the return and shipping costs of the new product.  ",
  },
  {
    title: "Collaborations ",
    desc: "Are you blogger, influence or work in the press?",
    desc1: "Write us to: contact@genuinslb.com",
  },
  {
    title: "Retail",
    desc: "Would you like to sell genuins In your store or being a distributor?",
    desc1: "Ask us information at: contact@genuinslb.com ",
  },
];

export function Faq() {
  return (
    <section className="px-8 py-20">
      <div className="container mx-auto">
        <div className="mb-14 text-center ">
          <Typography
            variant="h1"
            color="blue-gray"
            className="mb-4 text-4xl !leading-snug lg:text-[40px]"
          >
            Frequently asked questions
          </Typography>
        </div>
        <div className="max-w-3xl mx-auto grid gap-10">
          {faqs.map(({ title, desc, desc1 }) => (
            <div key={title}>
              <Typography
                color="blue-gray"
                className="pb-6 text-[20px] font-bold"
              >
                {title}
              </Typography>
              <div className="border-t border-gray-200 pt-4">
                <Typography
                  className="font-normal !text-gray-500"
                  dangerouslySetInnerHTML={{ __html: desc }}
                />
                <Typography
                  className="font-normal !text-gray-500 mt-4"
                  dangerouslySetInnerHTML={{ __html: desc1 }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Faq;
