import React from "react";
import { Link } from "react-router-dom";
const CustomBlockBanner = () => {
  return (
    <div className="shopify-section home-custom-block-baner-sections">
      <div className="home-custom-block layout_style_2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 no-padd">
              <div
                id="block-aa1076e5-d1fc-44bd-b178-c5c8898f45ee-largeImg-1"
                className="custom-block-banner banner-large-img"
              >
                <div className="img-box relative">
                  <a
                    href="https://genuins.com/pages/ethical"
                    className="adaptive_height"
                    style={{ paddingTop: "34.739583333333336%" }}
                  >
                    <img
                      href="https://genuins.com/pages/ethical"
                      alt="Genuins"
                      className="slide-pc lazyautosizes ls-is-cached lazyloaded"
                      data-sizes="auto"
                      sizes="1452px"
                      srcSet="//genuins.com/cdn/shop/files/ethical_1b74403c-5a75-4bba-931a-e5d9bfe7f957_1920x.jpg?v=1699980869"
                      data-srcset="//genuins.com/cdn/shop/files/ethical_1b74403c-5a75-4bba-931a-e5d9bfe7f957_1920x.jpg?v=1699980869"
                    />
                  </a>
                  <div className="banner-content inner abs_center">
                    <h2 className="banner-heading text-white font-bold sm:text-lg lg:text-3xl">
                      <span className="line"></span>
                      <span>WHO WE ARE</span>
                    </h2>
                    <p className="banner-des text-white">
                      <span>WE CARE FOR THE ENVIRONMENT AND FOR YOU</span>
                    </p>
                    <Link
                      to="/about"
                      className="banner-button mt-3 btn bg-transparent border border-gray-900 text-black min-w-[237px] inline-block px-4 py-2  transition duration-300 ease-in-out hover:bg-gray-800 hover:border-gray-800 hover:text-white"
                    >
                      <span>LEARN MORE</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
          @media (max-width: 767px),
          (min-width: 768px) and (max-width: 1199px) {
            #custom-block-aa1076e5-d1fc-44bd-b178-c5c8898f45ee {
              margin-bottom: 8px;
            }
          }
          @media (min-width: 1200px) {
            #custom-block-aa1076e5-d1fc-44bd-b178-c5c8898f45ee {
              margin-bottom: 6px;
            }
          }

          #custom-block-aa1076e5-d1fc-44bd-b178-c5c8898f45ee .img-box {
            text-align: center;
          }

          .banner-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
          }

          .banner-content span {
            display: block;
          }
        `}
      </style>
    </div>
  );
};

export default CustomBlockBanner;
