import ProductCard from "./ProductCard";
import { TitleHome } from "./TitleHome";

export default function ProductList({ products, text }) {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <TitleHome text={text} />

        <ProductCard products={products} />
      </div>
    </div>
  );
}
