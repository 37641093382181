import React, { useEffect, useState } from "react";
import { statesData } from "../data/UtilsAddress";
import { sumItems } from "../components/shoppingCart/ShoppingCartReducer";
import { useContext } from "react";
import ShoppingCartProductContext from "../components/shoppingCart/ShoppingCartProductContext";
const CustomerDetailsForm = (props) => {
  const { cartItems } = useContext(ShoppingCartProductContext);
  const { itemCount } = sumItems(cartItems);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessageErrorMessage] =
    useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");
  const [cityErrorMessage, setCityErrorMessage] = useState("");
  const [addressErrorMessage, setAddressErrorMessage] = useState("");

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    address: "",
    email: "",
    phone_number: "",
    city: "",
    state: "",
    address: "",
    country_id: "5fc3bfba-21b2-4e04-b9d3-157c594dc393",
    items: null,
  });

  const ClearInputData = () => {
    setFormData({
      firstname: "",
      lastname: "",
      address: "",
      email: "",
      phone_number: "",
      city: "",
      state: "",
      address: "",
      country_id: "5fc3bfba-21b2-4e04-b9d3-157c594dc393",
      items: null,
    });
  };
  useEffect(() => {
    setFormData({ ...formData, state: statesData.regions[0].value });
  }, []);
  function containsOnlyDigits(str) {
    const regex = /^\d+$/;
    return regex.test(str);
  }
  function isValidLebaneseNumber(str) {
    const regex = /^(03|76|70|71|78|79|81)\d{6}$/;
    return regex.test(str);
  }
  function containsOnlyLetters(str) {
    const regex = /^[a-zA-Z\s]{3,30}$/;
    return regex.test(str);
  }
  function isValidEmail(str) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(str);
  }
  function isValidAddress(str) {
    // const regex = /^[a-zA-Z0-9.\-_ ]{3,1000}$/;
    if (str !== undefined && str !== null && str.length > 3) {
      return true;
    }
    return false;
    // return regex.test(str);
  }
  const validateForm = () => {
    setPhoneNumberErrorMessageErrorMessage("");
    setEmailErrorMessage("");
    setFirstNameErrorMessage("");
    setLastNameErrorMessage("");
    setCityErrorMessage("");
    setAddressErrorMessage("");

    if (!containsOnlyLetters(formData.firstname)) {
      setFirstNameErrorMessage(" Please provide a valid first name");
      return;
    }

    if (!containsOnlyLetters(formData.lastname)) {
      setLastNameErrorMessage(" Please provide a valid last name");
      return;
    }

    if (!isValidEmail(formData.email)) {
      setEmailErrorMessage(" Please provide a valid email address");
      return;
    }

    if (!isValidLebaneseNumber(formData.phone_number)) {
      setPhoneNumberErrorMessageErrorMessage(
        " Please provide a valid phone number"
      );
      return;
    }
    if (!isValidAddress(formData.city)) {
      setCityErrorMessage(" Please provide a valid city");
      return;
    }
    if (!isValidAddress(formData.address)) {
      setAddressErrorMessage(" Please provide a valid address");
      return;
    }
    ClearInputData();
    props.scrollTo();
    props.loading(true);
    props.FormCustomerDetails(OrganiseData());
  };
  const OrganiseData = () => {
    const customerDetails = {
      shipping_customer_details: {
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        phone_number: formData.phone_number,
        state: formData.state,
        city: formData.city,
        address: formData.address,
        country_id: formData.country_id,
      },
      is_customer_details_same: true,
    };
    return customerDetails;
  };
  return (
    <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
      <p className="text-xl font-medium">Shipping information</p>
      <p className="text-gray-400">
        Complete your order by providing your address.
      </p>
      <div className="sm:col-span-3">
        <label
          htmlFor="firstname"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          First name
          <span className="text-red-600 text-xs ">{firstNameErrorMessage}</span>
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="firstname"
            id="firstname"
            onChange={(e) =>
              setFormData({ ...formData, firstname: e.target.value })
            }
            autoComplete="given-name"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div className="sm:col-span-3">
        <label
          htmlFor="lastname"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Last name
          <span className="text-red-600 text-xs ">{lastNameErrorMessage}</span>
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="lastname"
            id="lastname"
            onChange={(e) =>
              setFormData({ ...formData, lastname: e.target.value })
            }
            autoComplete="family-name"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div className="sm:col-span-4">
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Email address
          <span className="text-red-600 text-xs ">{emailErrorMessage}</span>
        </label>
        <div className="mt-2">
          <input
            id="email"
            name="email"
            type="email"
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            autoComplete="email"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div className="sm:col-span-2">
        <label
          htmlFor="phone_number"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Phone number
          <span className="text-red-600 text-xs ">
            {phoneNumberErrorMessage}
          </span>
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="phone_number"
            id="phone_number"
            onChange={(e) =>
              setFormData({ ...formData, phone_number: e.target.value })
            }
            autoComplete="phone_number"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="sm:col-span-3">
        <label
          htmlFor="country"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Country
        </label>
        <div className="mt-2">
          <select
            id="country"
            name="country"
            autoComplete="country-name"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset  sm:max-w-xs sm:text-sm sm:leading-6"
          >
            <option>Lebanon</option>
          </select>
        </div>
      </div>

      <div className="sm:col-span-2">
        <label
          htmlFor="state"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          State / Province
        </label>
        <div className="mt-2">
          {/* <input
            type="text"
            name="state"
            id="state"
            onChange={(e) =>
              setFormData({ ...formData, state: e.target.value })
            }
            autoComplete="address-level1"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
          /> */}

          <select
            id="state"
            name="state"
            onChange={(e) =>
              setFormData({ ...formData, state: e.target.value })
            }
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset  sm:max-w-xs sm:text-sm sm:leading-6"
          >
            {statesData.regions.map((region) => (
              <option key={region.value}>{region.label}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="sm:col-span-2 sm:col-start-1">
        <label
          htmlFor="city"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          City
          <span className="text-red-600 text-xs ">{cityErrorMessage}</span>
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="city"
            id="city"
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            autoComplete="address-level2"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div className="col-span-full">
        <label
          htmlFor="address"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Street address
          <span className="text-red-600 text-xs ">{addressErrorMessage}</span>
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="address"
            id="address"
            onChange={(e) =>
              setFormData({ ...formData, address: e.target.value })
            }
            autoComplete="address"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      {itemCount >= 1 ? (
        <button
          onClick={validateForm}
          className="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white"
        >
          Place Order
        </button>
      ) : (
        <button
          disabled
          className="mt-4 mb-8 w-full rounded-md bg-gray-400 px-6 py-3 font-medium text-white"
        >
          Place Order
        </button>
      )}
    </div>
  );
};

export default CustomerDetailsForm;
