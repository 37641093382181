import about_us_1 from "../assets/images/about_us_1.webp";
import about_us_2 from "../assets/images/about_us_2.webp";
import about_us_3 from "../assets/images/about_us_3.webp";
const features = [
  {
    name: "WHO ARE GENUINS?",
    description:
      "We are a 100% Spanish shoe brand founded in 2014. While that may seem young, we have a long history in the footwear manufacturing industry. In 1940 the first factory was founded, beginning a family tradition in the footwear manufacturing sector that would be passed down from generation to generation. It was by the third generation that the family began specialising in manufacturing cork sandals.",
  },
  {
    name: "MANUFACTURING",
    description:
      "At Genuins we go beyond traditional ideas, we are continually investigating new paths in design as well as new materials to create our collections. In addition to craftsmanship, of utmost importance to us is innovation, in terms of both design and manufacture. Key to this is our research and development team who are continually looking for new environmentally sound ways to bring product to market. We are committed to reducing the impact on the earth. Our shoes are made only with biodegradable water based adhesives and we consistently aiming to reduce our impact further. As a small factory we take our social responsibility very seriously.",
  },
];

export default function AboutUs() {
  return (
    <div className="bg-white">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            ABOUT US{" "}
          </h2>
          <p className="mt-4 text-gray-500">
            We are GENUINS, a young brand, 100% Made in Spain founded in 2014,
            although with a long history in the footwear industry.
            <br /> Now we are going to tell you how a beautiful story is born:
          </p>

          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{feature.name}</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          <img
            src={about_us_1}
            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
            className="rounded-lg bg-gray-100"
          />
          <img
            src={about_us_2}
            alt="Top down view of walnut card tray with embedded magnets and card groove."
            className="rounded-lg bg-gray-100"
          />
          <img
            src={about_us_3}
            alt="Side of walnut card tray with card groove and recessed card area."
            className="rounded-lg bg-gray-100"
          />
        </div>
      </div>
    </div>
  );
}
