import React from "react";
import Pos from "../assets/imagesNotCompressed/pos.jpg";
const PointOfSales = () => {
  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-4xl mx-auto py-8">
        <h1 className="text-3xl font-bold mb-4">Point of Sale</h1>
        <p className="ml-4 font-bold mb-4 text-lg">City mall - dora - GF</p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <img src={Pos} alt="Store" className="rounded-lg shadow-md" />
          </div>
          <div>
            <iframe
              title="Store Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.728196475231!2d35.56260787640442!3d33.89665332592872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f161d6d76d955%3A0x8921d25a1b7cdc4c!2sCity%20Mall!5e0!3m2!1sen!2sfr!4v1714307243055!5m2!1sen!2sfr"
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointOfSales;
