import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProductLoadingLogo from "../assets/logo/loading_product_image.png";
import { API_URL_IMAGE_RESIZED } from "../api/URL/constants";
import { Link } from "react-router-dom";

const ProductCard = ({ products }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="mt-6 grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
      {products.map((product) => (
        <div key={product.id} className="group relative w-full">
          <Link to={"/item/" + product.id}>
            <div
              style={{ backgroundImage: `url(${ProductLoadingLogo})` }}
              className="w-full h-0 pb-[125%] overflow-hidden rounded-md bg-no-repeat bg-center bg-cover bg-gray-200 relative group-hover:opacity-75"
            >
              {product.discount !== null && (
                <div className="absolute top-2 right-2 bg-red-500 text-white px-3 py-1 rounded-md text-xs font-semibold z-10">
                  -{product.discount}%
                </div>
              )}
              {product.outOfStock && (
                <div className="absolute bottom-2 left-2 bg-gray-800 text-white px-3 py-1 rounded-md text-xs font-semibold z-10">
                  Out of stock
                </div>
              )}
              {process.env.REACT_APP_DEV === "true" ? (
                <img
                  src={product.images[0].name}
                  className="absolute inset-0 w-full h-full object-cover"
                  alt={product.name}
                />
              ) : (
                <LazyLoadImage
                  src={
                    product.images[0]
                      ? `${API_URL_IMAGE_RESIZED}${product.images[0].name}?width=400&quality=90`
                      : ProductLoadingLogo
                  }
                  className="absolute inset-0 w-full h-full object-cover"
                  alt={product.name}
                />
              )}
            </div>
            <div className="mt-4 text-center">
              <h3 className="text-sm text-gray-700">{product.name}</h3>
              {product.discount !== null ? (
                <div className="mt-2">
                  <p className="text-sm font-medium text-gray-500 line-through">
                    {product.price}$
                  </p>
                  <p className="text-sm font-medium text-red-500">
                    $
                    {(
                      product.price -
                      (product.discount / 100) * product.price
                    ).toFixed(2)}
                  </p>
                </div>
              ) : (
                <p className="text-sm font-medium text-gray-900 mt-2">
                  {product.price}$
                </p>
              )}
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ProductCard;
