import React, { useState, useEffect } from "react";
import ProductList from "../components/ProductList";
import { productsMock } from "../data/MockData";
import { ItemFiltredAPI } from "../api/ItemFiltredAPI";
import { useLocation } from "react-router-dom";
const ProductPage = () => {
  const [loading, setLoading] = useState(true);
  const [productsFetchedData, setProductsFetchedData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [reachedBottom, setReachedBottom] = useState(false);
  const [parameters, setParameter] = useState({
    categoryId: null,
    typeId: null,
    collectionId: null,
    search: null,
    page: 1,
  });
  const [pagination, setPagination] = useState({
    current_page: null,
    last_page: null,
    per_page: null,
    total: null,
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // const fetchProducts = async () => {
  //   try {
  //     await ItemFiltredAPI.getAll(parameters.page + 1, parameters).then(
  //       (items) => {
  //         if (productsFetchedData.length < items.total) {
  //           const getProducts = items.data;
  //           setProductsFetchedData((prevProducts) => {
  //             const existingIds = new Set(
  //               prevProducts.map((product) => product.id)
  //             );
  //             const newProducts = getProducts.filter(
  //               (product) => !existingIds.has(product.id)
  //             );
  //             return [...prevProducts, ...newProducts];
  //           });

  //           setPagination({
  //             current_page: items.current_page,
  //             total: items.total,
  //             last_page: items.last_page,
  //             per_page: items.per_page,
  //           });
  //           setLoading(false);
  //           if (items.current_page < items.last_page) {
  //             setParameter({ ...parameters, page: parameters.page + 1 });
  //             setReachedBottom(false);
  //           } else {
  //             setLoadingProducts(false);
  //           }
  //         } else {
  //           setLoadingProducts(false);
  //           setLoading(false);
  //         }
  //       }
  //     );
  //     setLoading(false);
  //   } catch (error) {}
  // };
  useEffect(() => {
    scrollToTop();
    setLoading(true);
    if (process.env.REACT_APP_DEV === "true") {
      setLoading(false);
    } else {
      if (searchParams.has("category")) {
        const category = searchParams.get("category");
        setParameter({
          ...parameters,
          page: 1,
          typeId: null,
          collectionId: null,
          categoryId: category,
        });
      } else if (searchParams.has("type")) {
        const type = searchParams.get("type");
        setParameter({
          ...parameters,
          page: 1,
          typeId: type,
          collectionId: null,
          categoryId: null,
        });
      } else if (searchParams.has("collection")) {
        const collection = searchParams.get("collection");
        setParameter({
          ...parameters,
          categoryId: null,
          typeId: null,
          collectionId: collection,
          page: 1,
        });
      } else {
        try {
          ItemFiltredAPI.getAll(parameters.page, parameters).then(
            (products) => {
              setProductsFetchedData(products.data);
              setPagination({
                current_page: products.current_page,
                total: products.total,
                last_page: products.last_page,
                per_page: products.per_page,
              });
              setLoading(false);
            }
          );
        } catch (error) {}
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (
      parameters.categoryId !== null ||
      parameters.typeId !== null ||
      parameters.collectionId !== null ||
      parameters.page !== 1
    ) {
      try {
        ItemFiltredAPI.getAll(parameters.page, parameters).then((products) => {
          setProductsFetchedData(products.data);
          setPagination({
            current_page: products.current_page,
            total: products.total,
            last_page: products.last_page,
            per_page: products.per_page,
          });
          setLoading(false);
        });
      } catch (error) {}
    }
  }, [parameters.categoryId, parameters.typeId, parameters.collectionId]);

  useEffect(() => {
    const handleScroll = () => {
      const offsetHeight = document.documentElement.offsetHeight;
      const btmHeight = (offsetHeight * 20) / 100;
      const offsetHeightWithOutBtm = offsetHeight - btmHeight;
      const innerHeight = window.innerHeight;
      const scrollTop = document.documentElement.scrollTop;

      const hasReachedBottom =
        offsetHeightWithOutBtm - (innerHeight + scrollTop) <= 10;

      setReachedBottom(hasReachedBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await ItemFiltredAPI.getAll(parameters.page + 1, parameters).then(
          (items) => {
            if (productsFetchedData.length < items.total) {
              const getProducts = items.data;
              setProductsFetchedData((prevProducts) => {
                const existingIds = new Set(
                  prevProducts.map((product) => product.id)
                );
                const newProducts = getProducts.filter(
                  (product) => !existingIds.has(product.id)
                );
                return [...prevProducts, ...newProducts];
              });

              setPagination({
                current_page: items.current_page,
                total: items.total,
                last_page: items.last_page,
                per_page: items.per_page,
              });
              setLoading(false);
              if (items.current_page < items.last_page) {
                setParameter({ ...parameters, page: parameters.page + 1 });
                setReachedBottom(false);
              } else {
                setLoadingProducts(false);
              }
            } else {
              setLoadingProducts(false);
              setLoading(false);
            }
          }
        );
        setLoading(false);
      } catch (error) {}
    };
    if (reachedBottom) {
      if (productsFetchedData.length >= 12) {
        setLoadingProducts(true);
        setTimeout(() => {
          fetchProducts();
        }, 500);
      }
    }
  }, [reachedBottom]);

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div>
          <ProductList
            products={
              process.env.REACT_APP_DEV === "true"
                ? productsMock
                : productsFetchedData
            }
            text={"ALL Products"}
          />
          {loadingProducts && (
            <div className="flex mt-10 justify-center items-center ">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductPage;
