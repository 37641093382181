import React from "react";
import Carousel from "../components/Carousel";
import Category from "../components/Category";
import ProductList from "../components/ProductList";
import ProductRow from "../components/ProductRow";
import TextWithTopBar from "../components/TextWithTopBar";
import AboutSection from "../components/AboutSection";
import CustomBlockBanner from "../components/WhoWeAre";
import NewCollection from "../components/NewCollection";
import Man from "../assets/imagesNotCompressed/men home page.jpg";
import Women from "../assets/imagesNotCompressed/women home page.jpeg";
import kids from "../assets/imagesNotCompressed/kids.jfif";
import AquaType from "../assets/images/aquacomp.webp";
import GenuinsHome from "../assets/imagesNotCompressed/GENUINS HOME.jpeg";
import classics from "../assets/images/classicscomp.webp";
import ClogsType from "../assets/imagesNotCompressed/CLOGS.jpeg";
import { ProductMockBestSeller } from "../data/MockData";
import TypeHome from "../components/TypeHome";
import CarouselRow from "../components/ProductCaroussel";
const Home = () => {
  const types = [
    {
      //Genuins Home type men + women
      ids: [
        "880105e0-da42-4c01-9bb7-4b41fab8190c",
        "94dffd14-e9a3-4680-808f-f776c71fb4f6",
      ],
      name: "GENUINS HOME",
      imageSrc: GenuinsHome,
      imageAlt: "House slippers img",
      href: "#",
    },
    {
      ids: [
        "57417460-8808-44a5-a563-f64525bf73e2",
        "acce0726-0b1b-47e3-b3b4-3dbafa7f8bc9",
      ],
      name: "CLOGS",
      imageSrc: ClogsType,
      imageAlt: "Clogs img",
      href: "#",
    },
    {
      ids: [
        "a9e2bf5b-b410-4d6b-9f34-03718cf43a5f",
        "8453df31-20b2-4248-bcd7-ed0d662f79c7",
      ],
      name: "CLASSICS",
      imageSrc: classics,
      imageAlt: "Classics img",
      href: "#",
    },
  ];
  const category2 = [
    {
      id: "d2d883a8-b765-480c-a61a-29ff661c6690",
      name: "KIDS",
      imageSrc: kids,
      imageAlt: "House slippers img",
      href: "#",
    },
    {
      id: "c6a2851f-c427-4915-93c6-75c674b98664",
      name: "MEN",
      imageSrc: Man,
      imageAlt: "Clogs img",
      href: "#",
    },
    {
      id: "e1019b39-1d60-432c-817c-6b52a4ab0b17",
      name: "WOMAN",
      imageSrc: Women,
      imageAlt: "Classics img",
      href: "#",
    },
  ];
  return (
    <div>
      <Carousel />
      {/* <NewCollection /> */}

      <TextWithTopBar
        phrase={"100% HANDCRAFTED , DESIGNED AND PRODUCED IN SPAIN."}
      />
      <Category category={category2} />
      <CarouselRow products={ProductMockBestSeller} />

      <TextWithTopBar
        phrase={"THIS IS NOT A FAD. IT IS THE CONSCIOUS WAY TO MAKE FASHION."}
      />
      <TypeHome type={types} />
      {/* <ProductRow /> */}
      <AboutSection />
      <CustomBlockBanner />
    </div>
  );
};

export default Home;
