import React, { useState, useRef } from "react";

const SizeGuide = ({ title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const backdropRef = useRef(null);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseDrawer = (event) => {
    if (event.target === backdropRef.current) {
      setIsOpen(false);
    }
  };

  return (
    <div className="relative">
      <button className="text-black rounded-md" onClick={toggleDrawer}>
        {title}
      </button>
      <div
        ref={backdropRef}
        className={`fixed inset-0 overflow-y-auto z-50 flex justify-center items-center ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        } transition-opacity duration-900`}
        onClick={handleCloseDrawer}
      >
        <div className="absolute h-full top-0 bottom-0 right-0 w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4 bg-white px-8 ">
          <button
            className="absolute top-0 right-0 m-4 text-gray-500"
            onClick={toggleDrawer}
          >
            &times;
          </button>
          <h2 className="text-xl font-bold mb-4">SIZING</h2>
          <p className="mb-4 font-light">
            Our shoes fit to standard European sizing, take your usual EU size.
          </p>
          <p className="mb-4 font-light">
            If you have any questions regarding sizing, please reach out:{" "}
            <a href="mailto:hello@genuins.com.au" className="text-blue-500">
              contact@genuinslb.com
            </a>
          </p>
          <h3 className="text-lg font-bold mb-2">Women's Size Chart</h3>
          <table className="w-full mb-6">
            <thead>
              <tr>
                <th className="px-4 py-2">EU</th>
                <th className="px-4 py-2">AU / US</th>
                <th className="px-4 py-2">UK</th>
                <th className="px-4 py-2">Length (cm)</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-gray-200 text-center">
                <td className="px-4 py-2">35</td>
                <td className="px-4 py-2">4</td>
                <td className="px-4 py-2">2.5</td>
                <td className="px-4 py-2">22.5</td>
              </tr>
              <tr className="bg-gray-100 text-center">
                <td className="px-4 py-2">36</td>
                <td className="px-4 py-2">5</td>
                <td className="px-4 py-2">3.5</td>
                <td className="px-4 py-2">23</td>
              </tr>
              <tr className="bg-gray-200 text-center">
                <td className="px-4 py-2">37</td>
                <td className="px-4 py-2">6</td>
                <td className="px-4 py-2">4.5</td>
                <td className="px-4 py-2">24</td>
              </tr>
              <tr className="bg-gray-100 text-center">
                <td className="px-4 py-2">38</td>
                <td className="px-4 py-2">7</td>
                <td className="px-4 py-2">5</td>
                <td className="px-4 py-2">24.5</td>
              </tr>
              <tr className="bg-gray-200 text-center">
                <td className="px-4 py-2">39</td>
                <td className="px-4 py-2">8</td>
                <td className="px-4 py-2">5.5</td>
                <td className="px-4 py-2">25</td>
              </tr>
              <tr className="bg-gray-100 text-center">
                <td className="px-4 py-2">40</td>
                <td className="px-4 py-2">9</td>
                <td className="px-4 py-2">7</td>
                <td className="px-4 py-2">26</td>
              </tr>
              <tr className="bg-gray-200 text-center">
                <td className="px-4 py-2">41</td>
                <td className="px-4 py-2">10</td>
                <td className="px-4 py-2">7.5</td>
                <td className="px-4 py-2">26.5</td>
              </tr>
            </tbody>
          </table>
          <h3 className="text-lg font-bold mb-2 bg-white">Men's Size Chart</h3>
          <table className="w-full">
            <thead>
              <tr className="bg-gray-100 text-center">
                <th className="px-4 py-2">EU</th>
                <th className="px-4 py-2">AU / US</th>
                <th className="px-4 py-2">UK</th>
                <th className="px-4 py-2">Length (cm)</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-gray-200 text-center">
                <td className="px-4 py-2">41</td>
                <td className="px-4 py-2">8</td>
                <td className="px-4 py-2">7.5</td>
                <td className="px-4 py-2">26.5</td>
              </tr>
              <tr className="bg-gray-100 text-center">
                <td className="px-4 py-2">42</td>
                <td className="px-4 py-2">9</td>
                <td className="px-4 py-2">8</td>
                <td className="px-4 py-2">27</td>
              </tr>
              <tr className="bg-gray-200 text-center">
                <td className="px-4 py-2">43</td>
                <td className="px-4 py-2">10</td>
                <td className="px-4 py-2">9</td>
                <td className="px-4 py-2">28</td>
              </tr>
              <tr className="bg-gray-100 text-center">
                <td className="px-4 py-2">44</td>
                <td className="px-4 py-2">11</td>
                <td className="px-4 py-2">9.5</td>
                <td className="px-4 py-2">28.5</td>
              </tr>
              <tr className="bg-gray-200 text-center">
                <td className="px-4 py-2">45</td>
                <td className="px-4 py-2">12</td>
                <td className="px-4 py-2">10.5</td>
                <td className="px-4 py-2">29</td>
              </tr>
              <tr className="bg-gray-100 text-center">
                <td className="px-4 py-2">46</td>
                <td className="px-4 py-2">13</td>
                <td className="px-4 py-2">11.5</td>
                <td className="px-4 py-2">30</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SizeGuide;
