import React from "react";

const AboutSection = () => {
  return (
    <div className="bg-[#f0f0f0]">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
          <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
            {/* Item 1 */}
            <div className="item column-3 flex-grow text-center">
              <div className="image">
                <img
                  data-srcset="//genuins.com/cdn/shop/files/wall-clock_200x.png?v=1648551898"
                  alt="Genuins"
                  srcSet="//genuins.com/cdn/shop/files/wall-clock_200x.png?v=1648551898"
                  className="mx-auto"
                />
              </div>
              <div className="content mt-4">
                <p className="font-bold">DELIVERY TIME</p>
              </div>
              <p className="text-sm mt-3">
                Approximately 2 to 4 working days, although during sales and
                promotions it may increase somewhat more.
              </p>
            </div>

            {/* Item 2 */}
            <div className="item column-3 flex-grow text-center">
              <div className="image">
                <img
                  data-srcset="//genuins.com/cdn/shop/files/refund_200x.png?v=1648551898"
                  className="ls-is-cached lazyloaded mx-auto"
                  alt="Genuins"
                  srcSet="//genuins.com/cdn/shop/files/refund_200x.png?v=1648551898"
                />
              </div>
              <div className="content mt-4">
                <p className="font-bold">EXCHANGES AND RETURNS</p>
              </div>
              <p className="text-sm mt-3">
                If you want to make a change, you have 15 calendar days from the
                delivery of your order.{" "}
              </p>
            </div>

            {/* Item 3 */}
            <div className="item column-3 flex-grow text-center">
              <div className="image">
                <img
                  data-srcset="//genuins.com/cdn/shop/files/call_200x.png?v=1648551898"
                  className="ls-is-cached lazyloaded mx-auto"
                  alt="Genuins"
                  srcSet="//genuins.com/cdn/shop/files/call_200x.png?v=1648551898"
                />
              </div>
              <div className="content mt-4">
                <p className="font-bold">CONTACT</p>
              </div>
              <p className="text-sm mt-3">
                Phone: +961 81 848 985
                <br />
                Monday to Sunday: 10:00 to 22:00
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
